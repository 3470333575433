export enum CookiesNames {
  Consent = 'cookie-consent',
  SDUser = 'sd-user',
  FreeNumbers = 'free-numbers',
}

export const ONE_LINK = 'https://esimplus.onelink.me/WxwP/e8qgk4iq';

export const ESIMPLUS_LINK = 'https://esimplus.me';
const prefix = process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development' ? 'dev-' : '';
export const MOBILE_DATA_LINK = `https://${prefix}mobiledata.esimplus.me`;

export const MAIN_API_URL = process.env.NEXT_PUBLIC_SERVER_URL ?? 'https://api.esimplus.net/api';