export { default as FacebookIcon } from './facebook.svg';
export { default as TelegramIcon } from './telegram.svg';
export { default as LnIcon } from './ln.svg';
export { default as WhatWeAcceptIcon } from './what-we-accept.svg';
export { default as XMarkIcon } from './xmark.svg';
export { default as CheckIcon } from './check.svg';
export { default as MoonIcon } from './moon.svg';
export { default as SunIcon } from './sun.svg';
export { default as USDTIcon } from './usdt.svg';
export { default as CardIcon } from './card.svg';
export { default as CheckLiteIcon } from './check-lite.svg';
export { default as GlobeIcon } from './globe.svg';
