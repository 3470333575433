'use client';

import React from 'react';

function useOutsideClick(elementRef: React.RefObject<any>, onOutsideClick: (event: any) => void) {
  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onOutsideClick(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef, onOutsideClick]);
}

function useModalControls(initState = false, options?: { disableBodyScroll?: boolean }) {
  const [isOpen, setIsOpen] = React.useState(initState);

  const openModal = React.useCallback(() => setIsOpen(true), []);
  const closeModal = React.useCallback(() => setIsOpen(false), []);

  React.useEffect(() => {
    if (options?.disableBodyScroll) {
      const escapeKeyListener = (e: KeyboardEvent) => e.code === 'Escape' && closeModal();

      if (isOpen) {
        window.addEventListener('keydown', escapeKeyListener);
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
      } else {
        window.removeEventListener('keydown', escapeKeyListener);
        document.body.style.overflow = '';
        document.documentElement.style.overflow = '';
      }
    }
  }, [closeModal, isOpen, options?.disableBodyScroll]);

  return {
    isOpen,
    openModal,
    closeModal,
  };
}

export { useModalControls, useOutsideClick };
