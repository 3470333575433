const getTheme = (mode: 'light' | 'dark') => ({
  common: {
    white: '#FFFFFF',
    red: '#FF453A',
    neutralColor: '#999999',
    lightBlue: '#E0EAFD',
    blue: '#83B5F7',
    main: '#2253F5',
    gradient: 'linear-gradient(88deg, #94CF3D 44.05%, #08C3EC 72.41%, #3C2BDD 95.04%)'
  },
  mode,
  ...(mode === 'light' && {
    primary: {
      text: '#101010',
      revertText: '#FFF',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      bg: '#FFFFFF',
      searchInputBorderColor: 'rgba(0, 0, 0, 0.1)',
      htmlBg: '#ffffff',
      cardsBg: '#ffffff',
      navbarBg: '#ffffff',
      checkboxBorderColor: 'rgba(0, 0, 0, 0.5)',
      alertBg: 'rgba(217, 232, 252, 1)',
      modalBg: '#ffffff',
      crossBg: '#F0F0F0',
      planDetails: {
        bg: '#FAFAFA',
        boder: '#ffffff',
      },
    },
    secondary: {
      text: '#6F6F6F',
      btnBg: 'transparent',
      btnBorder: '#F0F0F0',
    }
  }),
  ...(mode === 'dark' && {
    primary: {
      text: '#FFF',
      revertText: '#101010',
      borderColor: 'rgba(255,255,255,0.1)',
      bg: 'radial-gradient(84.44% 84.68% at -3.96% -9.68%, #040D30 0%, #000006 100%)',
      searchInputBorderColor: 'rgba(128, 141, 158, 0.25)',
      htmlBg: '#000006',
      cardsBg: '#17171F',
      navbarBg: 'rgba(15,16,28,0.85)',
      checkboxBorderColor: 'rgba(237, 240, 250, 0.2)',
      alertBg: 'rgba(217, 232, 252, 0.3)',
      modalBg: 'rgba(255, 255, 255, 0.05)',
      crossBg: 'rgba(255, 255, 255, 0.1)',
      planDetails: {
        bg: 'rgba(255, 255, 255, 0.05)',
        boder: 'rgba(255, 255, 255, 0.1)',
      },
    },
    secondary: {
      text: 'rgba(255,255,255,0.65)',
      btnBg: 'rgba(255,255,255,0.1)',
      btnBorder: 'transparent',
    },
  })
  ,
  breakpoints: {
    values: {
      xs: 350,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
  }
});

export default getTheme;
